.create-review-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 200px;
    padding: 20px;
}

.create-review-form {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.create-review-form-textarea {
    height: 110px;
    resize: none;
}

.stars-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
}

.create-review-form-title {
    margin: 0;
    display: flex;
    align-items: center;
}
