.spot-preview-image {
    width: 270px;
    height: 250px;
    border-radius: 10px;
    background-image: url("https://static.wikia.nocookie.net/dexterslab/images/9/93/Dexter%27s_House.png/revision/latest?cb=20150503084954");
    background-size: 270px 250px;
}

.spot-index-item-container {
    width: 270px;
    /* margin-left: 42.5px;
    margin-right: 42.5px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;
    background-color: rgba(237, 237, 237, 0.402);
    box-shadow: 5px 5px 2px rgba(227, 227, 227, 0.657);
    padding: 10px;
    border-radius: 10px;
    height: fit-content;
    color: #00525E;
}

.spot-index-item-description-container {
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spot-index-item-price-container {
    width: 270px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.avg-rating-num {
    position: relative;
    top: 1px;
}

.medium {
    font-size: medium;
}

.fa-star-half {
    font-size: medium;
}
