.spots-grid {
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    /* grid-template-rows: repeat(auto-fit, minmax(300px, 1fr)); */
    list-style: none;
    height: 100%;
    margin: 0 auto 0 auto;
    padding: 0;
    max-width: 1500px;
}

@media screen and (min-width: 901px)  {
    .spots-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 586px) and (max-width: 900px) {
    .spots-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 585px) {
    .spots-grid {
        grid-template-columns: 1fr;
    }
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination-buttons {
    background-color: transparent;
    border: none;
    color: #333;
    margin: 0 0.25rem 1rem 0.25rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .pagination-buttons:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .pagination-buttons:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
