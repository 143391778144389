.spot-details-container {
    height: auto;
    padding: 0px 30px 30px 30px;
    width: 90%;
    max-width: 1500px;
    min-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.react-datepicker__input-container {
    width: 90% !important;
    margin: auto;
}

.react-datepicker__input-container > input {
    width: calc((100% - 37px));
    background-color: white;
    margin: auto;
}

.react-datepicker__input-container>svg {
    font-size: 14px;
}


.spot-details-image-container {
    display: grid;
    position: relative;
    grid-template-columns: 49.75% .25% 49.75%;
    grid-template-rows: 1fr;
    align-self: center;
    width: 100%;
}

.spot-details-preview-image {
    width: 100%;
    height: 412px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgb(202, 202, 202);
}

.spot-details-nonPreview-image-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2px;
}

.spot-details-nonpreview-image {
    width: 100%;
    height: 205px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgb(202, 202, 202);
}

.spot-details-information-container {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
    min-height: 200px;
}

.spot-details-information-text {
    width: 90%;
}

.spot-details-information-text {
    padding: 0 10px;
}

.spot-details-information-description {
    height: 100%;
}

.spot-details-information-button-container-wrapper {
    width: 100%;
}

.spot-details-information-button-container {
    border: solid 3px black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    top: 25px;
}

.spot-details-information-button-information {
    width: 85%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 39.5% 1% 59.5%;
    align-self: center;
}

.spot-details-reviews-summary-avgRating {
    font-size: 18pt;
}



.spot-details-information-button-price {
    display: flex;
    align-items: center;
}



.spot-details-information-button-reviews {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.spot-details-information-button {
    margin: auto;
    width: 85%;
}

.spot-details-reviews-summary-info {
    font-size: 18pt;
}

.enlarge {
    font-size: 18pt;
}

.post-review-button {
    height: 24pt;
    width: 160px;
    margin: auto;
}
