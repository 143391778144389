.nav-bar {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: center;
    max-width: 1500px;
    min-width: 500px;
    width: 100%;
    padding: 0 20px;
    height: 100%;
    margin: auto;
}

.profile-links{
    display: flex;
    align-items: center;
}

.create-spot-link{
    position: relative;
    right: 60px;
}

.manage-link {
    text-decoration: none;
    color: black;
}

.nav-bar-wrapper {
    height: 70px;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CCCCCC !important;
    background-color: white;
    box-sizing: border-box;
    margin: 0;
}

.nav-bar-logo {
    height: 50px;
}

.home-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    text-decoration: none;
    color: #ea547f
}

.home-button-text{
    font-size: 24pt;
}

.profile-button-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 40px;
    flex-direction: column;
    position: relative;
}

.profile-button {
    padding: 10px;
    right: 20px;
    height: 60%;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80px;
    position: relative;
    background-color: white;
    color: black;
    border: solid 2px #CCCCCC;
}

.profile-button-image{
    font-size: 130%;
}

.profile-dropdown {
    position: absolute;
    right: 0;
    top: 60px;
    border: solid black 1px;
    filter: drop-shadow(5px 5px 4px #999999);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    padding: 10px;
    width: 145px;
    background-color: white;
    z-index: 1;
}

.log-out-button-container {
    align-self: center;
}

.log-out-button {
    background-color: #999999;
    color: white;
    border-radius: 5px;
    border-color: black;
}

.dropdown-button {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
}

.dropdown-li {
    height: 30px;
    display: flex;
    align-items: center;

}

.error-list{
    list-style: none;
    align-self: flex-start;
    width: 100%;
    padding: 0;
    color: #FF5A5F;
}

.hidden {
    display: none
}
