.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 200px;
    padding: 20px;
}

.login-form {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.login-form-title {
    margin: 0;
    display: flex;
    align-items: center;
}

.with-errors1{
    height: 300px;
}
