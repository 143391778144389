/* TODO Add site wide styles */
* {
    font-family: 'Comic Neue', cursive;
    font-size: 14pt;
    font-weight: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

body {
    margin: 0;
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
    background-color: #f7f7fa;
}

.text-color-gray {
    color: #999999;
}

.page-content {
    position: relative;
    top: 70px;
    height: calc(100%-70px);
}

section {
    padding: 30px;
    background-color: white;
}

a {
    color: #00525E;
}

button {
    cursor: pointer;
}

h1 {
    font-size: 24pt;
    font-weight: bold;
    margin-top: 0;
}

h2 {
    font-size: 18pt;
    font-weight: bold;
}

input {
    width: 100%;
    background-color: #E8F0FE;
    border: solid 1px black;
    border-radius: 3px;
}

select {
    width: 100%;
    background-color: #E8F0FE;
    border: solid 1px black;
    border-radius: 3px;
}

label{
    width: 100%;
}

textarea {
    width: 100%;
    background-color: #E8F0FE;
    border: solid 1px black;
    border-radius: 3px;
}

.disabled {
    width: 101.5%;
    height: 50px;
    border-style: solid;
    background-color: white;
    border-color: #999999;
    box-shadow: 3px 3px #999999;
    color: #CCCCCC;
    cursor: default;
}

.enabled {
    width: 101.5%;
    height: 50px;
    border-style: solid;
    background-color: #FF5A5F;
    color: white;
    border-color: black;
    box-shadow: 3px 3px black;
    cursor: pointer;
}

.accent {
    width: 101.5%;
    height: 50px;
    border-style: solid;
    background-color: #999999;
    color: white;
    border-color: black;
    box-shadow: 3px 3px black;
    cursor: pointer;
}

p {
    width: 100%;
}
