.signup-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 200px;
    padding: 20px;
    height: 400px;
}

.signup-form {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.signup-form-title {
    margin: 0;
    display: flex;
    align-items: center;
}

.with-errors2{
    height: 500px;
}
