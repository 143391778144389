.main-footer {
    background-color: #f7f7fa;
    height: max-content;
    width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    border-top: 1px solid rgba(128, 128, 128, 0.171);
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute;
    bottom: 0; */
}

.main-footer-container{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.main-footer-icon {
    font-size: 30px;
    cursor: pointer;
    color: rgb(0,0,0,.8)
}

.logo-image{
    height: 30px;
}

.main-footer-row {
    display: flex;
    align-items: center;
}
